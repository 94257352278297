import { initializeApp, getApp } from 'firebase/app'
import { getAuth, onAuthStateChanged, onIdTokenChanged } from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator} from 'firebase/firestore'
import { getDatabase, connectDatabaseEmulator } from 'firebase/database'
import { getAnalytics, logEvent, setUserId } from 'firebase/analytics'
import { getRemoteConfig } from 'firebase/remote-config'
import {string} from './regex'

const envConfig = {
  apiKey: process.env.REACT_APP_PROD_API_KEY,
  appId: process.env.REACT_APP_PROD_APP_ID,
  authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_PROD_DATABASE_URL,
  projectId: process.env.REACT_APP_PROD_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_SENDER_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}

initializeApp(envConfig)

export const firebaseApp = getApp()
export const getFirebaseApp = getApp
export const auth = getAuth()
auth.useDeviceLanguage()
onAuthStateChanged(auth, (user) => {
  console.log('auth state changed', user)
})
onIdTokenChanged(auth, (user) => {
  console.log('id token changed', user)
})
export const firestore = getFirestore()
export const database = getDatabase()
export const remoteConfig = getRemoteConfig();
if (process.env.NODE_ENV !== 'production') remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
remoteConfig.defaultConfig = {
  "landing_hero_title_text": "Get To The Point"
};

// if (process.env.NODE_ENV !== 'production') {
//   connectFirestoreEmulator(firestore, "localhost", 8080)
//   connectDatabaseEmulator(database, "localhost", 9000)
// }
const analytics = typeof window !== "undefined" && getAnalytics()

export const ga = {
  log: (eventName, data) => {
    try {
      logEvent(analytics, eventName, data)
    }
    catch(err){ console.log('ga log error')}
  },
  setUserId: userId => setUserId(analytics, userId),
  click: (e, extra = {}) => {
    const value = e?.target?.title || e?.target?.innerText
    value && ga.log(`click_${string.replaceAllSpace(value, '_')}`, {value, ...extra})
  },
  notification: (severity, data) => ga.log(`notification-${severity}-rendered`, data),
}
