import last from "lodash/last";
const actions = {
  LOAD_FEED: "LOAD_FEED",
  LOAD_FEED_SUCCESS: "LOAD_FEED_SUCCESS",
  SYNC_FEED_SUCCESS: "SYNC_FEED_SUCCESS",
  LOAD_A_THREAD_SUCCESS: "LOAD_A_THREAD_SUCCESS",
  LOAD_A_THREAD: "LOAD_A_THREAD",
  PAGE_FEED_NEXT: "PAGE_FEED_NEXT",
  PAGE_FEED_NEXT_SUCCESS: "PAGE_FEED_NEXT_SUCCESS",
  LOAD_FEED_METADATA_SUCCESS: "LOAD_FEED_METADATA_SUCCESS",
  loadFeed: () => {
    return (dispatch, getState) => {
      // console.log("loadFeed")
      dispatch({
        type: actions.LOAD_FEED,
        params: {},
      });
    };
  },
  loadThread: (threadId) => {
    return dispatch => {
      dispatch({
        type: actions.LOAD_A_THREAD_SUCCESS,
        threadId,
      });
    };
  },
  pageNext: (source) => {
    return (dispatch, getState) => {
      const lastThreadId = last(getState().Feed?.list?.[source] || []);
      const startAfter = getState().Feed?.data?.[lastThreadId]?.lastTs;
      dispatch({
        type: actions.PAGE_FEED_NEXT,
        startAfter,
      });
    };
  },
};

export default actions;
