import {all, takeEvery, call, put} from "redux-saga/effects";
import actions from "./actions";
import {auth} from "@web/lib/firebase";
import {colls, buildQuery, getDocuments, getDocument, safeKeyForDB} from "@web/lib/firestore.db";
import {toFirestoreQueryItems, firestore as channel, toFirestoreDocumentItem} from "../channels";
import AlgoliaIndex from "@web/lib/algolia.db";

const indexes = {
  ...(Object.values(AlgoliaIndex.NAMES).reduce(($acc, name) => {
    $acc[name] = new AlgoliaIndex(name);
    return $acc;
  }, {})),
};

const source = "gmail";
/**
 * @summary Build and return a query
 * @param {String} [source]
 * @param {Number} [limit]
 * @param {Number | String} [startAfter]
 * @return {Query<DocumentData>}
 */
const getQuery = ({source = "gmail", limit = 50, startAfter}) => {
  return buildQuery(
    [colls.user, auth.currentUser.uid, source],
    {}, {order: [['lastTs', 'desc']], limit},
    {startAfter},
  );
};
/**
 * @summary Get documents by their IDs
 * @param {string[]} collectionPath
 * @param {String[]} documentIds
 * @return {Promise<FirestoreQueryItems>}
 */
const getDocumentsById = async (collectionPath = [], documentIds = []) => {
  const docs = await Promise.all(documentIds.map((documentId) => getDocument(collectionPath, documentId)));
  return toFirestoreQueryItems({docs});
};
/**
 * @summary Get all data groups for a list of feed
 * @param {FirestoreQueryItems} items
 * @return {{contactIds: string[], attachmentIds: string[], domainIds: string[]}}
 */
const getDocumentIdsForAdditionalDataGroups = ({list = [], data: threads = null}) => {
  return list.reduce(($acc, documentId) => {
    const threadData = threads[documentId];
    if (!threadData) return $acc;
    threadData?.contactEmails?.forEach((contactEmail) => {
      if (!contactEmail) return;
      $acc.contactIds.push(safeKeyForDB(contactEmail.toLowerCase()));
      $acc.domainIds.push(safeKeyForDB("https://" + contactEmail.split("@")[1].toLowerCase().trim() + "/"));
    });
    threadData.latest?.message?.attachments?.forEach((attachment) => attachment?.originId && $acc.attachmentIds.push(attachment?.originId));
    $acc.messageIds.push(...(threadData.newMessageIds || []));
    return $acc;
  }, {messageIds: [], contactIds: [], attachmentIds: [], domainIds: []});
};

/**
 * @summary sync feed
 * @generator
 * @return {Generator<*, void, *>}
 */
function* sync() {
  const listenTo = [{
    ref: getQuery({limit: 1}),
    source,
    success: actions.SYNC_FEED_SUCCESS,
  }];
  yield call(channel, listenTo);
}

/**
 * @summary Load feed
 * @generator
 * @param {object} params
 * @return {Generator<*, void, *>}
 */
function* load(params) {
  const querySnapshot = yield call(getDocuments, getQuery(params));
  const threads = toFirestoreQueryItems(querySnapshot);
  yield put({
    type: actions.LOAD_FEED_SUCCESS,
    payload: {threads},
    source,
  });
  console.log("LOAD_FEED_SUCCESS", threads);
  const {messageIds = [], contactIds = [], attachmentIds = [], domainIds = []} = getDocumentIdsForAdditionalDataGroups(threads);
  const [summary, actionables, contacts, attachments, domains] = yield all([
    indexes.summary.getObjectsByIds(messageIds),
    indexes.actions.getObjectsByIds(messageIds),
    indexes.contacts.getObjectsByIds(contactIds),
    getDocumentsById([colls.user, auth.currentUser.uid, "attachments"], attachmentIds),
    getDocumentsById(["http-domains"], domainIds),

  ]);
  yield put({
    type: actions.LOAD_FEED_METADATA_SUCCESS,
    payload: {
      source, summary, actionables, contacts, attachments, domains,
    },
  });
}
/**
 * Load and sync feed
 * @param {Object} action
 * @param {Object} action.params
 * @return {Generator<*, void, *>}
 */
function* loadAndSync({params = {}}) {
  yield call(load, params);
  yield call(sync, params);
}
function* loadAThread({threadId}) {
  const doc = yield call(
    getDocument,
    [colls.user, auth.currentUser.uid, source].join("/"),
    threadId,
  );
  yield put({
    type: actions.LOAD_A_THREAD_SUCCESS,
    payload: toFirestoreDocumentItem(doc),
    source,
  });
}
function* pageNext({startAfter}) {
  const querySnapshot = yield call(getDocuments, getQuery({startAfter}));
  yield put({
    type: actions.PAGE_FEED_NEXT_SUCCESS,
    payload: {threads: toFirestoreQueryItems(querySnapshot)},
    source,
  });
}
export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FEED, loadAndSync),
    takeEvery(actions.LOAD_A_THREAD, loadAThread),
    takeEvery(actions.PAGE_FEED_NEXT, pageNext),
  ]);
}
