import { all } from "redux-saga/effects";
// import appSagas from "./app/sagas";
// import authSagas from "./auth/sagas";
// import accountSagas from "./account/sagas";
import feedSagas from "./feed/sagas";

export default function* rootSaga(getState) {
  yield all([
    // appSagas(),
    // authSagas(),
    // accountSagas(),
    feedSagas(),
  ]);
}
