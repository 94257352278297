import search, {SearchIndex, SearchClient, SearchOptions, SearchResponse, DeleteResponse, ChunkedBatchResponse, SaveObjectResponse, PartialUpdateObjectsOptions, PartialUpdateObjectResponse} from "algoliasearch";
const client = search(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_API_KEY);
// console.log("REACT_APP_ALGOLIA_APP_ID", process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_API_KEY);
/**
 * @summary Convert a list of records to a map  keyed by objectID
 * @param {object[]} records
 * @returns {FirestoreQueryItems}
 */
const toMap = (records = []) => records.reduce(($acc, record) => {
  if (!record?.objectID) return $acc;
  const {objectID, ...hit} = record;
  $acc.data[objectID] = hit;
  $acc.list.push(objectID);
  return $acc;
}, {data: {}, list: []});
/**
 * @class AlgoliaIndex
 * @property {SearchIndex} index
 */
export default class AlgoliaIndex {
  /**
   * @summary Constructor
   * @param {string} indexName
   */
  constructor(indexName) {
    this.index = client.initIndex(indexName);
  }
  /**
   * @summary Handle search response error
   * @param {Error} error
   * @return {Error}
   */
  #toSearchResponseError(error) {
    return error;
  }
  /**
   * @summary Execute a search of this index with query
   * @param {String} query
   * @param {SearchOptions} searchOptions
   * @return {Promise<SearchResponse>}
   */
  #indexSearch = (query, searchOptions = null) =>
    this.index
      .search(query, searchOptions)
      .catch(this.#toSearchResponseError);

  /**
   * @summary Search index
   * @param {String} query
   * @param {SearchOptions} searchOptions
   * @return {Promise<SearchResponse>}
   */
  async search(query, searchOptions) {
    return this.#indexSearch(query, searchOptions)
      .catch((error) => {
        console.error("Error searching index", error);
        return error;
      });
  }
  test() {
    this.search("test", {
      hitsPerPage: 1,
      page: 0,
      restrictSearchableAttributes: [
        'attribute'
      ],
      attributesToRetrieve: [],
      attributesToHighlight: [],
      attributesToSnippet: [],
      relevancyStrictness: 90,
      highlightPreTag: "",
      highlightPostTag: "",
      snippetEllipsisText: "",
      restrictHighlightAndSnippetArrays: false,
      responseFields: "*",
      facets: [],
      filters: "",
      facetFilters: [],
      maxValuesPerFacet: 100,
      facetingAfterDistinct: false,
      sortFacetValuesBy: "count",
      aroundLatLng: "",
      aroundLatLngViaIP: false,
      aroundRadius: "",
      aroundPrecision: 1,
      minimumAroundRadius: "",
      insideBoundingBox: [],
      queryType: "prefixLast",
      insidePolygon: [],
      ignorePlurals: false,
      removeStopWords: false,
      advancedSyntax: false,
      optionalWords: [],
      removeWordsIfNoResults: "none",
      disableExactOnAttributes: [],
      exactOnSingleWordQuery: "attribute",
      alternativesAsExact: ["ignorePlurals", "singleWordSynonym"],
      numericFilters: [],
      tagFilters: [],
    })
  }
  /**
   * @summary Get index records by objectID
   * @param {string[]} objectIds
   * @returns {object[]}
   */
  async getObjectsByIds(objectIds) {
    return this.index.getObjects(objectIds)
      .then((response) => toMap(response.results))
      .catch((err) => {
        console.log("An error occurred getting objectID");
        return err;
      });
  }
  /**
   * @summary Add/update a index document
   * @param {String} objectID
   * @param {Object} updates
   * @param {PartialUpdateObjectsOptions} [options]
   * @return {Promise<PartialUpdateObjectResponse>}
   */
  async updateDocument(objectID, updates, options) {
    if (!objectID) {
      throw new Error("Missing objectID");
    }
    return this.index.partialUpdateObject({...updates, objectID}, {createIfNotExists: true})
      .then((partialUpdateObjectResponse) => partialUpdateObjectResponse)
      .catch((error) => {
        console.error("Error updating document", error);
        return error;
      });
  }
  /**
   * @summary Add/update a index document
   * @param {String} objectID
   * @param {Object} data
   * @return {Promise<SaveObjectResponse>}
   */
  async saveDocument(objectID, data) {
    if (!objectID) {
      throw new Error("Missing objectID");
    }
    await this.index.saveObject({...data, objectID})
      .then((savedObjectResponse) => savedObjectResponse)
      .catch((error) => {
        console.error("Error saving document", error);
        return error;
      });
  }
  /**
   * @summary Update multiple documents
   * @param {Object<[String, Object]>[]} dataList
   * @return {Promise<Readonly<ChunkedBatchResponse>>}
   */
  async saveDocuments(dataList) {
    if (!dataList.every((docItem) => !!docItem[0])) throw new Error("A document is missing objectID");
    const documents = dataList.map(([objectID, data]) => ({...data, objectID}));
    return this.index.saveObjects(documents)
      .then((chunkedBatchResponse) => chunkedBatchResponse)
      .catch((err) => {
        console.error("Error saving documents", err);
        return err;
      });
  }
  /**
   * @summary Remove object from index
   * @param {string} objectID
   * @return {Promise<DeleteResponse>}
   */
  async remove(objectID) {
    console.log("Removing object from algolia index", objectID);
    return this.index.deleteObject(objectID)
      .then((deleteResponse) => deleteResponse)
      .catch((err) => {
        console.error("Error deleting documents", err);
        return err;
      });
  }
  static NAMES = {
    MESSAGES: "messages",
    ATTACHMENTS: "attachments",
    CONTACTS: "contacts",
    ANNOTATIONS: "annotations",
    SUMMARY: "summary",
    ACTIONS: "actions",
  };
}
