import { call, cancelled, put, take } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { onSnapshot } from "firebase/firestore";

/**
 * @typedef {Object} FirestoreQueryItems
 * @property {Object} data
 * @property {string[]} list
 */

/**
 * @typedef {Object} FirestoreDocumentItem
 * @property {Object} data
 * @property {string} id
 */

/**
 * Transform a query snapshot into a data object and a list of ids
 * @param {FirebaseFirestore.QuerySnapshot | {docs: object}} querySnapshot
 * @return {FirestoreQueryItems}
 */
export function toFirestoreQueryItems(querySnapshot) {
  return querySnapshot.docs.reduce(($acc, document) => {
    $acc.data[document.id] = document.data();
    $acc.list.push(document.id);
    return $acc;
  }, {data: {}, list: []});
}

/**
 * Transform a document snapshot into a data object and an id
 * @param {FirebaseFirestore.DocumentSnapshot} documentSnapshot
 * @return {FirestoreDocumentItem}
 */
export function toFirestoreDocumentItem(documentSnapshot) {
  return {data: documentSnapshot.data(), id: documentSnapshot.id};
}
const getPayload = snapshot => {
  return snapshot.id ?
    toFirestoreDocumentItem(snapshot) :
    toFirestoreQueryItems(snapshot);
};
let activeActions = [];
const listenTo = (actions = []) => {
  // First, prevent duplicate listeners
  const newActions = actions.filter(({ success, key }) => activeActions.indexOf(key || success) < 0);
  activeActions = activeActions.concat(newActions.map(({ success, key }) => key || success));
  return eventChannel(emit => {
    newActions.forEach(({ ref, success, error, ...rest }) => {
      onSnapshot(ref, snapshot => {
        emit({
          payload: getPayload(snapshot),
          type: success,
          ...rest,
        });
      });
    });
    return () => null;
  });
};
export function* firestore(actions = []) {
  const channel = yield call(listenTo, actions);
  try {
    while (true) {
      const result = yield take(channel);
      yield put(result);
    }
  } finally {
    if (yield cancelled()) channel.close();
  }
}
