function extractWebUrls(text) {
  // Regular expression to match valid URLs (source: https://www.regextester.com/94502)
  const urlRegex = /(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?/gi;

  // Extract URLs using the match method
  const urlsList = text.match(urlRegex) || [];
  return urlsList.filter(url => {
    if (!url.startsWith('http') && !url.startsWith('https')) {
      url = 'https://' + url;
    }

    let urlObj;
    try {
      urlObj = new URL(url);
    } catch {
      return false;
    }

    // Rule 1, 2, 3: Check protocol
    if (urlObj.protocol !== 'http:' && urlObj.protocol !== 'https:') return false;

    // Rule 4: Check for spaces
    if (url.includes(' ')) return false;

    // Rule 5: Check for unwanted file extensions
    if (/\.(jpg|jpeg|png|gif|bmp|tiff|pdf|mp4|mkv|flv|avi|mov|wmv|webm)$/i.test(urlObj.pathname)) return false;

    // Rule 11: Ignore IP addresses
    if (/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(urlObj.hostname)) return false;

    // Rule 12: Ignore authentication credentials
    if (urlObj.username || urlObj.password) return false;

    // Rule 13: Ignore emojis
    if (/[\u{1F600}-\u{1F64F}]/u.test(url)) return false;

    // Rule 14: Ignore URLs with more than three subdomains
    if (urlObj.hostname.split('.').length > 4) return false;

    // Rules 2.1, 2.2, 10: Check TLD and non-standard characters
    const tld = urlObj.hostname.split('.').pop().toLowerCase();
    if (!/^([a-z]{2,5})$/.test(tld) || !['com', 'net', 'org', 'ai', 'co', 'io'].includes(tld)) return false;

    return true;
  }).map(url => {
    // Rule 7: Remove trailing slash
    url = url.replace(/\/$/, '');

    // Rule 8: Remove trailing #
    url = url.replace(/#$/, '');

    return url;
  });
}

// Example usage:
// const text = 'Here are some URLs: https://example.com, https://www.example.org, www.example.net, example.com, www.example.co, www.example.ai/path/to/resource, http://192.168.1.1, https://user

export const urlFind = {
  extractValidWebpageUrls: (text) => extractWebUrls(text),
  exists: new RegExp('((http|ftp|https):\\/\\/)?[\\w\\-_]+(\\.[\\w\\-_]+)+([\\w\\-\\.,@?^=%&:/~\\+#]*[\\w\\-\\@?^=%&/~\\+#])?'),
  httpsOnly: /(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?/
}
export const string = {
  replaceAllSpace: (str, value) => str.replace(/ /g, value),
  replacePunctuationAndSpaces: (str, replaceWith = '_') => str.replace(/[.",/#!?$%^&*;:{}=\-_`~()]/g, replaceWith).replace(/\s/g, replaceWith),
}

/**
 * Extract user handles from a string of text
 * User handles are in the form @username
 * Multiple handles can be in a single string
 * @param {string} text
 * @returns {string[]}
 */
export const extractUserHandles = (text) => {
  const regex = /@([a-zA-Z0-9_-]+)/g
  const matches = []
  let match
  while ((match = regex.exec(text))) {
    matches.push(match[1])
  }
  return matches
}

/**
 * Replace all instances of a user handle in the format @user-name in a string of text with a link.
 * Links are in the format <a href="/chat/user-name">@user-name</a>
 * @param {string} text
 * @returns {string}
 */
export const replaceUserHandleWithLink = (text) => {
  const regex = /@([a-zA-Z0-9_-]+)/g
  return text.replace(regex, '<a href="/chat/$1">@$1</a>')
}

/**
 * Split text string into an array with text between ``` and ``` as a single element
 * @param {string} text
 * @returns {string[]}
 */
export const splitTextByCodeBlock = (text) => {
  const regex = /```(.*?)```/g
  return text.split(regex)
}
