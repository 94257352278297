import actions from "./actions";
import uniq from "lodash/uniq";
import reduce from 'lodash/reduce'

const initState = {
  isLoading: false,
  errorMessage: false,
  data: {},
  summary: [],
  actionables: [],
  contacts: [],
  attachments: [],
  domains: [],
};
/**
 * @summary Merge state
 * @param {object} groupState
 * @param {object[]} list
 * @param {string} source - The source of the data example: gmail, outlook, yahoo
 * @return {*&{data: (*)}}
 */
const mergeGroup = (groupState = {}, list = [], source) => {
  // const {list = []} = groupItem || {};
  return {
    // [source]: uniq([...(state[source] || []), ...payload.list]),
    all: uniq([...(groupState.all || []), ...list]),
  };
};
const mergeNetwork = (contactsState = {}, contactsData = {}, contactsList = [], threadsData = {}, summaryData = {}, actionablesData = {}) => {
  const idLookup = reduce(contactsData, ($acc, contact, contactId) => {
    $acc[contact.email] = contactId;
    return $acc;
  }, {});

  return {
    idLookup,
    all: uniq([...(contactsState.all || []), ...contactsList]),
    ...(reduce(threadsData, ($acc, thread, threadId) => {
      const {email: fromEmail} = threadsData[threadId]?.latest?.message?.from || {};
      const contactId = idLookup[fromEmail];
      const messageId = threadsData[threadId]?.newMessageIds?.[0];
      if (summaryData[messageId]) {
        $acc.summary[contactId] = $acc.summary[contactId] || [];
        $acc.summary[contactId].push(messageId);
      }
      if (actionablesData[messageId]) {
        $acc.actionables[contactId] = ($acc.actionables[contactId] || []);
        $acc.actionables[contactId].push(messageId);
      }
      return $acc;
    }, {actionables: {}, summary: {}})),
  };
};
/**
 * @summary Reducer for feeds
 * @param {object} state
 * @param {string} type
 * @param {{
 * threads: FirestoreQueryItems,
 * summary: FirestoreQueryItems,
 * actionables: FirestoreQueryItems,
 * contacts: FirestoreQueryItems,
 * attachments: FirestoreQueryItems,
 * domains: FirestoreQueryItems,
 * }} payload
 * @param {string} [source=gmail]
 * @return {*}
 */
export default function reducer(state = initState, {type, payload, source = "gmail"}) {
  switch (type) {
    case actions.LOAD_FEED_SUCCESS:
    case actions.SYNC_FEED_SUCCESS:
    case actions.PAGE_FEED_NEXT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          threads: {...state.data.threads, ...(payload.threads?.data || null)},
        },
        threads: mergeGroup(state.threads, payload?.threads?.list, source),
      };
    case actions.LOAD_FEED_METADATA_SUCCESS:
      console.log("LOAD_FEED_METADATA_SUCCESS", payload);
      return {
        ...state,
        data: {
          threads: {...state.data.threads},
          summary: {...state.data.summary, ...payload.summary.data},
          actionables: {...state.data.actionables, ...payload.actionables.data},
          contacts: {...state.data.contacts, ...payload.contacts.data},
          attachments: {...state.data.attachments, ...payload.attachments.data},
          domains: {...state.data.domains, ...payload.domains.data},
        },
        summary: mergeGroup(state.summary, payload.summary.list, source),
        actionables: mergeGroup(state.actionables, payload.actionables.list, source),
        contacts: mergeNetwork(state.contacts, payload.contacts.data, payload.contacts.list, state.data.threads, payload.summary.data, payload.actionables.data),
        attachments: mergeGroup(state.attachments, payload.attachments.list, source),
        domains: mergeGroup(state.domains, payload.domains.list, source),
      };
    default:
      return state;
  }
}
